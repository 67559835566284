body, li,a,p,h1,h2,h3,h4,h5,h6,span,label,input{
  font-family: 'Lato', sans-serif;
}
a{
  color: inherit;
  text-decoration: none;
}
.logwrap {
  width: 100%;
  float: left;
  display: table;
  height: 100vh;
}
.logwrapinner {
  width: 100%;
  vertical-align: middle;
  display: table-cell;
}
.logo {
  width: 100%;
  float: left;
  text-align: center;
  margin-bottom: 30px;
}
.mid {
  max-width: 35%;
  margin: 0 auto;
}
.logforminner label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.logforminner input {
  border: 1px solid #665589;
  height: 60px;
  border-radius: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.form-control:focus {
  border-color: #665589;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}
p.logtext {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #27145C;
}
p.submitbut .btn {
  background: #A4D233;
  border-radius: 30px;
  width: 100%;
  margin-top: 10px;
  height: 65px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #27145C;
}
/* end */

/* nav css */
.my-bg
{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
div#navbarTogglerDemo02 ul.navbar-nav {
  margin-left: auto;
}
div#navbarTogglerDemo02 ul.navbar-nav li a.nav-link.active {
  font-weight: 700;
}
div#navbarTogglerDemo02 ul.navbar-nav li a.nav-link {
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: #27145C !important;
}
/* end */

/* scandata */
.pt-50{
  padding-top: 50px;
}
.form-select:focus, textarea#Description:focus {
  border-color: #665589;
  outline: 0;
  box-shadow: 0 0 0 0 rgb(13 110 253 / 0%);
}
textarea#Description {
  resize: none;
  border-color: #665589;
}
h2.dataheading {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: #27145C;
}
p.filterby {
  margin-bottom: 5px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}
.filtercontaine .swh {
  margin-right: 15px;
}
.filtercontaine .swh, .filtercontaine .sws {
  width: 235px;
  float: left;
}
.sws, .swh {
  border: 1px solid #665589;
}
.swh select {
  border: none;
}
.sws input, .sws button {
  border: none;
}
button#button-addon2 {
  background-color: transparent;
  color: rgba(0, 0, 0, 1);
}
.filtercontaine {
  width: 100%;
  float: left;
}
.rcw button {
  background-color: transparent;
  padding: 8px 20px;
  border: 1px solid #27145C;
  border-radius: 30px;
  color: #27145C;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.22px;
  text-transform: uppercase;
}
.rcw {
  width: 100px;
  float: right;
}
/* end */

/* table css */
button.di img {
  width: 35px;
}
button.di {
  padding: 0px;
  border: 0px;
  background-color: transparent;
}
.dis {
  border: 1px solid #27145C !important;
  text-decoration: none;
  font-size: 16px;
  padding: 5px 13px !important;
  border-radius: 30px;
  margin-left: 10px;
  color: #27145C;
  margin-right: 10px;
}
span.stn {
  font-weight: 500;
  color:#000000;
}
.table thead {
  background-color: #665589;;
}
.table thead th {
  color: #fff;
}
.table th, .table tr td {
  font-size: 14px;
  font-family: lato;
  text-align: center;
}
.table tr td {
  color:#000000;
}
.tabledata, .datasheet, .paginationcont {
  width: 100%;
  float: left;
}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  float: left;
}
table > tbody tr:last-child {
  border-bottom: 1px solid #000000;
}
/* end */
.size{
  margin-bottom: 30px;
}
/* pagination css */

.paginationcont .totalentry {
  width: 30%;
  float: left;
}
.paginationcont nav {
  width: 70%;
  float: right;
  text-align: right;
}
ul.pagination {
  float: right;
}
.paginationcont nav ul.pagination .page-link {
  margin: 0px 5px;
  border-radius: 4px;
  border-color: #4C4C4C;
  color: #000000;
}
.paginationcont nav ul.pagination .page-link:focus {
  background-color: #C4C6C5;
  box-shadow: none;
}
/* end */

/* button css */

.rightme {
  width: 100%;
  float: left;
  text-align: right;
}
.eab button {
  border: none;
  background: transparent;
}
button.excel {
  background-color: transparent;
  padding: 8px 20px;
  border: 1px solid #27145C;
  border-radius: 30px;
  color: #27145C;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.22px;
  text-transform: uppercase;
}
.eab button.excel{
  margin-right: 10px;
}
button.addnew {
  background: #A4D233;
  border-radius: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.22px;
  text-transform: uppercase;
  color: #27145C;
  padding: 8px 20px;
}
/* end */

/* modal css */
.modal-dialog .modal-header .modal-title {
  font-size: 16px !important;
}
.modal-dialog .modal-header {
  padding: 8px 15px;
}
.modal-dialog label {
  color: #000000;
  font-weight: 400;
  font-size: 14px;
}
.modal-dialog input, .modal-dialog select {
  border: 1px solid #665589;
  border-radius: 0;
}
.modal-dialog button.btn-close {
  font-size: 12px;
  color: #000000 !important;
  opacity: 1;
}

/* media query */
@media screen and (max-width:767px){
  .filtercontaine .swh, .filtercontaine .sws {
    width: 100%;
    float: left;
    margin-bottom: 10px;
  }
  .rcw {
    width: 100%;
    float: left;
  }
  a.navbar-brand img {
    width: 165px;
  }
  div#navbarTogglerDemo02 ul.navbar-nav li a.nav-link {
    text-align: left;
  }
  .mid {
    max-width: 100%;
    margin: 0 auto;
  }
  .logforminner input {
    height: 45px;
  }
  p.submitbut .btn {
      height: 45px;
      font-size: 16px;
  }
  .logwrap {
      height: 80vh;
  }
  .table th, .table tr td {
      white-space: nowrap;
  }
  .table-responsive {
    margin-top: 15px;
  }
  .paginationcont .totalentry, .paginationcont nav {
    width: 100%;
  }
  ul.pagination {
    float: left;
  }
  p.entry {
    margin-bottom: 5px;
  }
  .paginationcont nav ul.pagination .page-link {
    padding: 0px 8px;
  }
  .rightme {
    text-align: left;
  }
  .pt-50 {
    padding-top: 25px;
  }
  button:focus:not(:focus-visible) {
    box-shadow: none;
    border-color: #665589;
  }
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #ffffffed;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: #dddddd;
  border-top-color: #665589;
  -webkit-animation: spin 4s linear infinite; 
animation: spin 4s linear infinite;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
100% {
  transform: rotate(360deg);
}
}